import { Box, Typography } from "@mui/material"

// import app components
import Head from "../components/head"

const Page = () => {
  return (
    <div>
      <Head title="404" />

      <Box
        sx={{
          height: "calc(100vh - 64px)",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
        }}
      >
        <Typography variant="h2">Oops! We couldn&apos;t find the page you were looking for!</Typography>
      </Box>
    </div>
  )
}

Page.showNavbar = true

export default Page
